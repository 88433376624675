.card {
  width: 700px;
  height: 300px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
  position: relative;
  transition: border-color 0.3s ease;
  border-radius: 20px;
  border: 1px solid rgba(219, 219, 219, 0.13);
}

.card-text {
  transition: transform 0.3s ease;
  text-align: start;
  position: absolute;
  bottom: 10px;
  left: 40px;
  bottom: 20px;
  width: 300px;
  font-weight: bold;
}

.card:hover .card-text {
  transform: translateY(-10px);
}

.large-card {
  margin-right: 20px;
}

.small-cards {
  display: flex;
  flex-direction: column;
}

.small-cards .card {
  width: 480px;
  height: 140px;
  margin: 5px;
}

.small-cards-below {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.large-card-below {
  margin-left: 20px;
  margin-top: 5px;
}

.large-card-below .card {
  height: 290px;
}

@media (max-width: 1196px) {
  .card {
    width: 500px;
    height: 140px;
  }

  .about {
    flex-direction: column;
    align-items: center;
  }

  .small-cards {
    flex-direction: column;
    align-items: center;
    margin-top: -15px;
  }

  .small-cards-below {
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }

  .small-cards .card {
    width: 500px;
    height: 140px;
  }

  .large-card {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .large-card-below {
    margin-right: 20px;
  }

  .large-card-below .card {
    height: 140px;
  }
}

@media (max-width: 480px) {
  .card {
    width: 340px;
    height: 140px;
  }

  .card-text {
    left: 20px;
  }

  .about {
    flex-direction: column;
    align-items: center;
  }

  .small-cards {
    flex-direction: column;
    align-items: center;
  }

  .small-cards .card {
    width: 340px;
    height: 150px;
  }

  .large-card {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .large-card-below {
    margin-right: 20px;
  }
}
