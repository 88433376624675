.projects-title {
  font-size: 3rem;
  margin-bottom: 60px;
  margin-top: 80px;
}

#text-project {
  color: #cbacf9;
}

@media (max-width: 480px) {
  .projects-title {
    font-size: 2.8rem;
  }
}
