.tech-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 80px;
}

.scroll {
  position: relative;
  display: flex;
  width: 1000px;
  overflow: hidden;
  mask-image: linear-gradient(
    90deg,
    transparent,
    #fff 20%,
    #fff 80%,
    transparent
  );
}

.scroll div {
  white-space: nowrap;
  animation: animate var(--t) linear infinite;
  animation-delay: calc(var(--t) * -1);
}

.scroll div span {
  display: inline-flex;
  margin: 10px;
  background: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.5;
  letter-spacing: 0.2em;
}

.scroll div span:hover {
  background: #4caf50;
  cursor: pointer;
}

.scroll div:nth-child(2) {
  animation: animate2 var(--t) linear infinite;
  animation-delay: calc(var(--t) / -2);
}

.imgbx img {
  max-width: 100px;
  scale: 0.8;
  filter: grayscale(1);
}

.imgbx img:hover {
  filter: grayscale(0);
}

@keyframes animate {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes animate2 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-200%);
  }
}

@media (max-width: 1196px) {
  .scroll {
    width: 800px;
  }
}

@media (max-width: 1017px) {
  .scroll {
    width: 600px;
  }

  .scroll div span {
    font-size: 0.8em;
    letter-spacing: 0.2em;
  }

  .imgbx img {
    max-width: 80px;
    scale: 0.8;
  }
}

@media (max-width: 480px) {
  .scroll {
    width: 380px;
  }
}
