.projects-section {
  margin-top: 40px;
  text-align: center;
  margin-bottom: 80px;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  justify-content: center;
  margin: 0 auto;
  max-width: 1200px;
}

.project-card {
  background-color: #0c1327;
  border: 1px solid rgba(201, 201, 201, 0.411);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 2.15);
  transition: transform 0.5s ease, box-shadow 0.5s ease;
  max-width: 600px;
  padding: 20px;
}

.project-card:hover {
  cursor: pointer;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 13);
}

.project-image-container {
  margin-bottom: 15px;
  overflow: hidden;
  border-radius: 10px;
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.project-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 5px;
  text-align: left;
  margin-left: 10px;
}

.project-subtitle {
  font-size: 1rem;
  color: #666;
  margin: 0;
  margin-bottom: 10px;
  text-align: left;
  margin-left: 10px;
}

.project-description {
  text-align: left;
  margin: 0;
  margin-bottom: 15px;
  margin-left: 10px;
}

.technology-icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  border: 1px solid rgba(0, 128, 0, 0.267);
  border-radius: 50%;
  padding: 5px;
  position: relative;
  top: 0px;
  margin-right: -12px;
}

.tech-logo {
  width: 20px;
  height: 20px;
}

.button-tech-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.technology-icons {
  display: flex;
}

.button-icon-container {
  display: flex;
  align-items: center;
}

.send-icon {
  margin-left: 10px;
  color: #cbacf9;
  cursor: pointer;
}

.send-icon:hover {
  color: #8f79af;
}

.text-button {
  color: #cbacf9;
  text-decoration: none;
}

.text-button:hover {
  color: #8f79af;
}

@media (max-width: 1196px) {
  .project-card {
    max-width: 500px;
  }

  .projects-grid {
    width: 340px;
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .projects-grid {
    grid-template-columns: 1fr;
    width: 340px;
  }

  .project-card {
    min-height: 430px;
    max-height: 300px;
    padding: 15px;
  }

  .technology-icons {
    margin-top: 10px;
  }
}
