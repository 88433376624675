.box-container {
  margin-top: 40px;
}

.info-box-container {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping of items */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin: 20px 0; /* Optional margin */
  border-radius: 30px;
}

.info-box-container > div {
  flex: 1 1 calc(50% - 20px); /* Adjust to make two columns with some gap */
  margin: 10px; /* Margin between boxes */
  max-width: 600px; /* Optional max width for each box */
}

.who-title {
  font-size: 4rem;
  margin-bottom: 40px;
}

.who {
  color: #cbacf9;
}

@media (max-width: 1196px) {
  .info-box-container > div {
    flex: 1 1 100%;
    height: 300px;
    max-width: 480px;
  }
}

@media (max-width: 1017px) {
  .info-box-container > div {
    max-width: 540px;
  }
}

@media (max-width: 480px) {
  .info-boxes-container {
    flex-direction: column;
    align-items: center;
  }

  .info-box-container > div {
    flex: 1 1 100%;
    height: auto;
    max-width: 340px;
    padding: 20px;
  }

  .who-title {
    font-size: 2.5rem;
  }
}
