html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Hanken Grotesk", sans-serif;
  overflow-x: hidden;
  user-select: none;
}

.banner-bg {
  background-image: url("../../Assets/Images/backgrounds/background.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
}

.content {
  margin-top: 100px;
  z-index: 2;
  text-align: center;
  position: relative;
}
