.introduction,
.name-title,
.developer-info {
  margin: 0;
  padding: 5px 0;
}

.introduction {
  font-size: 1.2rem;
  color: #cbacf9;
  margin-top: 10px;
}

.name-title {
  font-size: 4rem;
  font-weight: bold;
  max-width: 700px;
  margin: 0 auto;
}

.developer-info {
  font-size: 2rem;
  color: #dfcbfc;
}

#ux {
  color: #cbacf9;
}

@media (max-width: 768px) {
  .name-title {
    font-size: 2.5rem;
  }
  .introduction {
    font-size: 1rem;
  }
  .developer-info {
    font-size: 1.2rem;
    padding: 0 30px 0 30px;
  }
}

@media (max-width: 480px) {
  .developer-info {
    padding: 20px;
  }
}
