.zyra-container {
  margin-top: 40px;
}

.zyra-title {
  font-size: 4rem;
  margin-bottom: 40px;
}

.zyra {
  color: #cbacf9;
}

@media (max-width: 768px) {
  .zyra-title {
    font-size: 2.5rem;
  }
}
